import React from 'react'
import ProductPage from '../../components/ProductPage'
const breadcrumb = [
  {
    link: '/',
    title: 'Home',
  },
  {
    title: 'Software',
  },
]

const SoftwarePage = () => {
  const top_image =
    process.env.CLOUDINARY_URL +
    '/f_auto/website/vertical/0b_1920x704_darker_coding-1853305_1920_s7nwju.jpg'
  const process_image = process.env.CLOUDINARY_URL + '/f_auto/website/2_1920px_DSC5520_kj2hce.jpg'
  const obj_1 = 'website/vertical/Images/05/2_dbjwts.jpg'
  const obj_2 = 'website/vertical/Images/05/3_whdprv.jpg'
  const obj_3 = 'website/vertical/Images/05/4_hs3hir.jpg'
  const big_content_image = 'website/vertical/Images/05/1_egrbay.jpg'
  const process_icon_1 = 'website/vertical/request_aelnpm.svg'
  const process_icon_2 = 'website/vertical/assignment_nvfjoa.svg'
  const process_icon_3 = 'website/vertical/Icons/11b_computer-screen_ghnyng.png'
  const icon_obj_1 = 'website/vertical/Icons/7_software_r7yrmd.svg'
  const icon_obj_2 = 'website/vertical/Icons/5_laptop_fodgzn.png'
  const icon_obj_3 = 'website/vertical/Icons/9_Quiz_clu6eq.svg'
  const icon_obj_4 = 'website/vertical/Icons/10_saving_lbnyod.svg'
  const offer_image_1 = 'website/vertical/Images/footer/f.jpg'
  const offer_image_2 = 'website/vertical/Images/footer/fjycjamv30imkmcrfb5m.jpg'
  const offer_image_3 = 'website/vertical/Images/footer/a.jpg'
  const offer_image_4 = 'website/vertical/Images/footer/b.jpg'
  const offer_image_5 = 'website/vertical/Images/footer/c.jpg'
  const offer_image_6 = 'website/vertical/Images/footer/d.jpg'

  return (
    <ProductPage
      page="product-software"
      top_image={top_image}
      heading_title={'Software'}
      heading_sub_title={'Die richtigen Tools für jede Arbeit'}
      catalog_title={'Software'}
      catalog_big_title={'Unsere Produkte'}
      obj_number={3}
      obj_small_image={true}
      obj_1={obj_1}
      obj_1_title={'Office Basics'}
      obj_1_description={'Microsoft Office, Kaspersky, Skype, One Drive \n u. v. m.'}
      obj_2={obj_2}
      obj_2_title={'Communication Essentials'}
      obj_2_description={'Z.B. Microsoft Outlook, Slack, DocuSign, Mailbutler oder Time Clock'}
      obj_3={obj_3}
      obj_3_title={'Analytics Starterpack'}
      obj_3_description={'Microsoft Azure, Tableau, IBM Analytics, Power BI \n u. v. m.'}
      second_catalog_title={'Software'}
      second_catalog_big_title={'Übersicht'}
      big_content_image={big_content_image}
      product_title={'Unser Software-Angebot'}
      product_description={
        'Über uns bekommen Sie sofort mit der Elektronik auch die nötige Software, ob Microsoft Office, Kommunikationstools wie Slack oder spezielle Analytics Programme – und das auf Wunsch sogar vorinstalliert auf Ihrem Computer.'
      }
      workplace={'Kaspersky'}
      price={'4'}
      period={'monatlich'}
      icon_obj_1={icon_obj_1}
      bullet_1={'Große Auswahl aus 600+ Programmen'}
      icon_obj_2={icon_obj_2}
      bullet_2={'Auf Wunsch vorinstalliert auf Ihrem Computer'}
      icon_obj_3={icon_obj_3}
      bullet_3={'Inklusive Customer Support für alle Programme'}
      icon_obj_4={icon_obj_4}
      bullet_4={'Monatlich kündbar und zu vergünstigten Konditionen'}
      process_image={process_image}
      process_title={'PROZESS'}
      process_big_title={'So buchen Sie Ihre Software'}
      process_icon_1={process_icon_1}
      process_title_1={'Anfrage'}
      process_description_1={
        'Sie nennen uns die Programme, mit denen Sie arbeiten, die Mitarbeiterzahl und sonstige Wünsche. Wir beraten Sie umfänglich bei der Auswahl der richtigen Software und erstellen Ihnen ein persönliches Angebot.'
      }
      process_icon_2={process_icon_2}
      process_title_2={'Auftrag'}
      process_description_2={
        'Wählen Sie ganz flexibel Ihr individuelles Software-Paket und entscheiden sich für eine Lizenzversion. Buchen Sie auf Wunsch auch noch Laptops hinzu. So bleiben Sie flexibel und können monatlich kündigen.'
      }
      process_icon_3={process_icon_3}
      process_title_3={'Plug & Play'}
      process_description_3={
        'Sie erhalten Ihre gewünschten Software-Zugänge umgehend – wenn gewünscht auch sofort vorinstalliert auf Ihrem Computer mit Auslieferung des bestellten Gerätes. Damit sind Sie sofort startklar.'
      }
      offer={'ANGEBOT'}
      offer_title={'Weitere Produkte'}
      offer_image_1={offer_image_1}
      offer_title_1={'Elektronik'}
      offer_link_1={'/elektronik/'}
      offer_image_2={offer_image_2}
      offer_title_2={'Services'}
      offer_link_2={'/services/'}
      offer_image_3={offer_image_3}
      offer_title_3={'Büromöbel'}
      offer_link_3={'/bueroeinrichtung/moebel/'}
      offer_image_4={offer_image_4}
      offer_title_4={'Akustik-Lösungen'}
      offer_link_4={'/bueroeinrichtung/akustikloesungen/'}
      offer_image_5={offer_image_5}
      offer_title_5={'Telefonboxen'}
      offer_link_5={'/bueroeinrichtung/telefonboxen/'}
      offer_image_6={offer_image_6}
      offer_title_6={'Pflanzen'}
      offer_link_6={'/bueroeinrichtung/pflanzen/'}
      downloadLinkFull="/kataloge/elektronik/"
      customName={'ELEKTRONIK-KATALOG DOWNLOADEN'}
      breadcrumb={breadcrumb}
    />
  )
}

export default SoftwarePage
